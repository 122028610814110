import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact">
            <div className="contact-info">
                <h2>CONTACT US</h2>
                <div className="contact-item">
                    <h3>Questions or Comments?</h3>
                    <p>Send me a message or ask me a question on Whatsapp or email</p>
                    <a
                        href="https://api.whatsapp.com/send?phone=919760763318&text=Hello%20Amit!%20I%20would%20like%20to%20inquire%20about%20your%20wedding."
                        target="_blank"
                        rel="noopener noreferrer"
                        className="whatsapp-button"
                    >
                        Message us on WhatsApp
                    </a>
                </div>
                <div className="contact-item">
                    <h3>Deepti And Amit Together</h3>
                    <p>JAI MAA BHAGWATI HOTEL, near Jai Shree Balaji Banquet, Aincholi, Pithoragarh, Uttarakhand, India</p>
                    <p><a href="mailto:amitphulera999@gmail.com">amitphulera999@gmail.com</a></p>
                </div>
            </div>
            <div className="map-container">
                <iframe
                    title="Jai Maa Bhagwati Hotel Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3470.4046481275523!2d80.204670376042!3d29.562827541228057!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a1253eb7168123%3A0x41af9e258b08beb4!2sJAI%20MAA%20BHAGWATI%20HOTEL!5e0!3m2!1sen!2sin!4v1716055652765!5m2!1sen!2sin&disableDefaultUI=true&zoom=15&scaleControl=false&mapTypeControl=false&streetViewControl=false&rotateControl=false&fullscreenControl=false"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
                <a 
                    href="https://www.google.com/maps/dir/?api=1&destination=Jai+Maa+Bhagwati+Hotel" 
                    target="_blank"
                    rel="noopener noreferrer"
                    className="directions-button"
                >
                    Get Directions
                </a>
            </div>
        </div>
    );
};

export default Contact;
