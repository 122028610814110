import React from 'react';
import Header from './components/Header';
import Timer from './components/Timer';
import CeremonyCard from './components/CeremonyCard';
import FAQ from './components/FAQ';
import RSVP from './components/RSVP';
import Contact from './components/Contact';
import './App.css';

const App = () => {
    return (
        <div className="app">
            <Header />
            <Timer />
            <div className="ceremonies">
                <CeremonyCard
                    image="https://ucarecdn.com/6bfad961-ebc5-4976-9a40-65f32668281b/-/preview/1000x666/"
                    title="Haldi"
                    description="The haldi ceremony is a joyous pre-wedding ritual where turmeric paste is applied to the bride and groom, signifying purity, cleansing, and protection, while preparing them for their journey together."
                />
                <CeremonyCard
                    image="https://ucarecdn.com/9a2f1d30-b738-486c-af01-adbceec972be/-/preview/999x667/"
                    title="Mehendi"
                    description="The mehendi ceremony is a vibrant pre-wedding tradition where intricate henna designs are applied to the bride's hands and feet, symbolizing love, joy, and the strengthening of the marital bond."
                />
                <CeremonyCard
                    image="https://ucarecdn.com/4cef5734-4ce4-43ff-adae-d45a06156636/-/preview/1000x666/"
                    title="Byah(Marriage)"
                    description="Byah or a Kumaoni wedding is a vibrant celebration filled with traditional rituals, folk cholia dance and music, colorful attire, and heartfelt hospitality, reflecting the rich cultural heritage of the Kumaon region in Uttarakhand.
                    Experience the enchanting traditions of a Kumaoni wedding – join us on 11th July in celebrating love, culture, and joyous festivities amidst the scenic beauty of Uttarakhand!"
                />
                <CeremonyCard
                    image="https://ucarecdn.com/4f878110-2d9e-4b24-8f83-a63ec6ecdd29/-/preview/1000x665/"
                    title="Reception"
                    description="Celebrate with Deepti and Amit at their reception, enjoying food, music, and festivities as we toast to their new journey together."
                />
            </div>
            <FAQ />
            <RSVP />
            <Contact />
        </div>
    );
};

export default App;
