import React, { useState, useEffect } from 'react';
import { auth, googleProvider } from '../firebaseConfig';
import { signInWithPopup } from 'firebase/auth';
import Modal from './Modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './RSVP.css';
import './Modal.css'; // Ensure this is imported to apply the styles

const RSVP = () => {
    const [showModal, setShowModal] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [extraDetails, setExtraDetails] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isRegistered, setIsRegistered] = useState(null);
    const [rsvpSubmitted, setRsvpSubmitted] = useState(false);
    const [rsvpError, setRsvpError] = useState('');

    const vercelFunctionUrl = '/api';

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            setUserName(user.displayName);
            setUserEmail(user.email);
            setIsAuthenticated(true);
            setShowModal(true);
        } catch (error) {
            console.error('Error signing in with Google', error);
        }
    };

    const validate = () => {
        const errors = {};
        const phoneRegex = /^\+?[1-9]\d{1,14}$/;

        if (!arrivalDate) {
            errors.arrivalDate = 'Date of arrival is required.';
        }

        if (!phoneNumber) {
            errors.phoneNumber = 'Phone number is required.';
        } else if (!phoneRegex.test(phoneNumber)) {
            errors.phoneNumber = 'Phone number must be a valid international number.';
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleRSVP = async () => {
        if (!validate()) return;

        const payload = {
            email: userEmail,
            name: userName,
            date_of_arrival: arrivalDate.toISOString().split('T')[0],
            phone_number: phoneNumber,
            extra_details: extraDetails,
        };

        try {
            const response = await fetch(`${vercelFunctionUrl}/submit_rsvp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setRsvpSubmitted(true);
                setRsvpError('');
                setTimeout(() => {
                    setShowModal(false);
                    setRsvpSubmitted(false);
                }, 3000);
            } else if (response.status === 400) {
                const data = await response.json();
                if (data.message === 'An RSVP with this email already exists.') {
                    setRsvpError('You have already RSVPed.');
                } else {
                    setRsvpError('Failed to submit RSVP.');
                }
            } else {
                setRsvpError('Failed to submit RSVP.');
            }
        } catch (error) {
            console.error('Error submitting RSVP', error);
            setRsvpError('Error submitting RSVP');
        }
    };

    const highlightDates = [
        { date: new Date(2024, 6, 10), className: 'highlight-haldi', tooltip: 'Haldi and Mehandi' },
        { date: new Date(2024, 6, 11), className: 'highlight-shadi', tooltip: 'Byah (Wedding)' },
        { date: new Date(2024, 6, 12), className: 'highlight-reception', tooltip: 'Reception' }
    ];

    const renderDayContents = (day, date) => {
        const highlight = highlightDates.find(h => h.date.toDateString() === date.toDateString());
        return (
            <div className={`day-highlight ${highlight ? highlight.className : ''}`} title={highlight ? highlight.tooltip : ''}>
                {day}
            </div>
        );
    };

    return (
        <div className="rsvp-container">
            <div className="heartfelt-message">
                <h2>We Can't Wait to Celebrate with You!</h2>
                <p>
                    Your presence at our wedding means the world to us. By RSVPing, you'll help us make sure
                    everything is perfect for you. We can't wait to share this special day with all our loved ones. Please let us know if 
                    you have any special requests or requirements, and we'll do our best to accommodate them.
                </p>
                <button onClick={handleGoogleSignIn} className="rsvp-button">
                    RSVP by Signing in with Google
                </button>
            </div>

            <Modal show={showModal} onClose={() => setShowModal(false)}>
                {rsvpSubmitted ? (
                    <p className="success-message">Your RSVP is submitted. Keep an eye on your inbox!</p>
                ) : rsvpError ? (
                    <p className="error-message">{rsvpError}</p>
                ) : isAuthenticated ? (
                    <div className="rsvp-form">
                        {isRegistered === true ? (
                            <p>Welcome back, {userName}! You have already registered for the wedding. Please update your information if needed.</p>
                        ) : (
                            <p>Thank you, {userName}, for planning to attend our wedding. We are thrilled to celebrate this special day with you!</p>
                        )}
                        <p>We need some additional information to make your experience better. Please let us know your date of arrival and phone number:</p>
                        <div className="info-inputs">
                            <DatePicker
                                selected={arrivalDate}
                                onChange={(date) => setArrivalDate(date)}
                                minDate={new Date(2024, 6, 1)}
                                maxDate={new Date(2024, 6, 15)}
                                renderDayContents={renderDayContents}
                                placeholderText="Select a date"
                                className="date-picker"
                            />
                            {errors.arrivalDate && <p className="error">{errors.arrivalDate}</p>}
                            <input
                                type="tel"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                placeholder="+1234567890"
                                className="phone-input"
                            />
                            {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                        </div>
                        <textarea
                            value={extraDetails}
                            onChange={(e) => setExtraDetails(e.target.value)}
                            placeholder="Do you have any requirements or anything to share?"
                            className="extra-details-input"
                        />
                        <button onClick={handleRSVP} className="submit-rsvp-button">
                            Submit RSVP
                        </button>
                    </div>
                ) : (
                    <button onClick={handleGoogleSignIn} className="google-signin-button">
                        RSVP by signing in with Google
                    </button>
                )}
            </Modal>
        </div>
    );
};

export default RSVP;
