import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <div className="overlay">
                <h1>Deepti and Amit Together</h1>
                <p>Join us in celebrating the wedding of Deepti and Amit</p>
            </div>
        </div>
    );
};

export default Header;
