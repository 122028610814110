import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: 'What is the date of the wedding?',
            answer: (
                <>
                    <p>The wedding will take place on July 11, 2024.</p>
                </>
            )
        },
        {
            question: 'Where is the venue?',
            answer: (
                <>
                    <p>The venue for Haldi and Mehandi for Amit is his residence in Gurna, Pithoragarh</p>
                    <p>Deepti's Haldi and Mehandi ceremonies will take place in Jai Maa Bhagwati Hotel</p> 
                    <p>The venue for marriage and reception is Jai MAA Bhagwat Hotel, near Jai Shree Balaji Banquet, Aincholi, Pithoragarh, Uttarakhand, India.</p>
                </>
            )
        },
        {
            question: 'What should I wear?',
            answer: (
                <>
                    <p>Feel free to wear traditional attire that you are comfortable in. We want you to enjoy the day as much as we do.</p>
                    <p>But if you are looking for recommendations</p>
                    <ul>
                        <li>Traditional Indian attire in some shade of yellow for Haldi ceremony</li>
                        <li>Traditional Indian attire in some hue of green for Mehandi ceremony</li>
                        <li>Either traditional or western attire for Shadi</li>
                        <li>Western attire for Reception.</li>
                    </ul>
                </>
            )
        },
        {
            question: 'How can I RSVP?',
            answer: (
                <>
                    <p>You can RSVP by signing in with Google and filling out the RSVP form on our website.</p>
                </>
            )
        },
        {
            question: 'Where will I stay?',
            answer: (
                <>
                    <p>We have booked some confortable stays for you nearby Pithoragarh. Please let us know beforehand about your stay requirements so that we can arrange accordingly.</p>
                </>
            )
        },
        {
            question: 'How can I reach Pithoragarh?',
            answer: (
                <>
                    <ul>
                        <li><strong>Travel Options to Pithoragarh from Delhi</strong></li>
                        <ul>
                            <li><strong>By Cab</strong></li>
                            <ul>
                                <li>Direct Shared Cabs: Vehicles like Dezire and Ertiga. Cost: Approx. ₹2,500. Duration: 10 hours.</li>
                            </ul>

                            <li><strong>By Train</strong></li>
                            <ul>
                                <li>Train to Haldwani/Kathgodam: Followed by a shared cab.</li>
                            </ul>

                            <li><strong>By Air</strong></li>
                            <ul>
                                <li>Helicopter: From Haldwani via Air Heritage.</li>
                                <li>Flight: From Pantnagar via FlyBig.</li>
                            </ul>

                            <li><strong>By Bus</strong></li>
                            <ul>
                                <li>Direct Buses: Available from Delhi.</li>
                            </ul>

                            <li><strong>Notes</strong></li>
                            <ul>
                                <li>Book Flights in Advance: Limited seat availability.</li>
                                <li>Check Baggage Policies: Ensure compliance with airline policies.</li>
                            </ul>
                        </ul>

                        <li><strong>Travel Options to Pithoragarh from Dehradun</strong></li>
                        <ul>
                            <li><strong>By Air</strong></li>
                            <ul>
                                <li>Direct Flight: Available from Dehradun.</li>
                            </ul>

                            <li><strong>By Cab</strong></li>
                            <ul>
                                <li>Comfortable Journey: Book a cab.</li>
                            </ul>
                        </ul>

                        <li><strong>Cab Operator Contacts</strong></li>
                        <ul>
                            <li>From Delhi/Haldwani: +91 99978 07878</li>
                            <li>From Delhi: +91 98379 74859</li>
                            <li>From Haldwani: +91 98974 38572</li>
                        </ul>

                        <li><strong>Adventurous Travel Options</strong></li>
                        <ul>
                            <li>Direct Buses: Available from both Delhi and Dehradun.</li>
                        </ul>
                    </ul>

                </>
            )
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h2>Frequently Asked Questions</h2>
            <div className="accordion">
                {faqs.map((faq, index) => (
                    <div key={index} className="accordion-item">
                        <div className="accordion-question" onClick={() => toggleAccordion(index)}>
                            {faq.question}
                        </div>
                        <div className={`accordion-answer ${activeIndex === index ? 'active' : ''}`}>
                            {faq.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default FAQ;