import React, { useState, useEffect } from 'react';
import './Timer.css';

const Timer = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date("2024-07-11") - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className="timer">
            <h2>IT'S ALMOST HERE!</h2>
            <p>The date is getting closer and we are getting everything ready.</p>
            <div className="countdown">
                <div className="time-box">
                    <span className="time">{timeLeft.days || 0}</span>
                    <span className="label">Days</span>
                </div>
                <div className="time-box">
                    <span className="time">{timeLeft.hours || 0}</span>
                    <span className="label">Hours</span>
                </div>
                <div className="time-box">
                    <span className="time">{timeLeft.minutes || 0}</span>
                    <span className="label">Minutes</span>
                </div>
                <div className="time-box">
                    <span className="time">{timeLeft.seconds || 0}</span>
                    <span className="label">Seconds</span>
                </div>
            </div>
        </div>
    );
};

export default Timer;
